<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 px-0">
        <dx-data-grid
          id="notificationGrid"
          ref="notificationGridRef"
          :data-source="dataSource"
          :height="setHeight"
          :cache-enabled="false"
          :allow-column-reordering="true"
          :allow-column-resizing="true"
          :column-auto-width="true"
          :show-column-lines="true"
          :show-row-lines="true"
          :show-borders="true"
          :hover-state-enabled="true"
          :row-alternation-enabled="false"
          @initialized="onInitialized"
          @content-ready="onContentReady"
          @row-removed="rowRemoved"
        >
          <!--region    ::DataGird base components -->
          <dx-sorting mode="single" />
          <dx-remote-operations :paging="true" :filtering="false" :sorting="true" :summary="false" />
          <dx-search-panel :visible="true" :width="200" placeholder="Search..." />
          <dx-paging :page-size="pagerOptions.pageSize" />
          <dx-pager
            :allowed-page-sizes="pagerOptions.allowedPageSizes"
            :show-info="pagerOptions.showInfo"
            :show-navigation-buttons="pagerOptions.showNavigationButtons"
            :show-page-size-selector="pagerOptions.showPageSizeSelector"
            :visible="pagerOptions.visible"
          />
          <!--endregion ::DataGird base components -->
          <!--region    ::DataGird columns -->
          <dx-column data-field="icon" caption="" :width="40" :allow-sorting="false" cell-template="iconCellTemplate" />
          <template #iconCellTemplate="{ data }">
            <div>
              <p-icon
                :name="data.data.read ? 'bi bi-check2' : 'bi bi-exclamation-triangle-fill'"
                :color="data.data.avatarType"
                size="20px"
                :class="data.data.read ? 'text-success' : 'text-warning notif-alert'"
                class="notif-alert"
              />
            </div>
          </template>
          <dx-column data-field="topic" :allow-sorting="false" cell-template="topicCellTemplate" width="100" />
          <template #topicCellTemplate="{ data }">
            {{ getEnumFormated($enums.NotificationTypeEnum, data.value) }}
          </template>
          <dx-column caption="Message" width="80%" :allow-sorting="false" cell-template="messageCellTemplate" />
          <template #messageCellTemplate="{ data }">
              <a href="javascript:void(0)" class="alert-link" @click="onShowNotificationLink(data)">
                {{ getMessage(data) }}
              </a>
          </template>
          <dx-column data-field="severity" width="100px" :allow-sorting="false" cell-template="severityCellTemplate" />
          <template #severityCellTemplate="{ data }">
            {{ getEnumFormated($enums.NotificationSeverityEnum, data.value) }}
          </template>
          <dx-column data-field="creationTime" width="120px" caption="Create Date" data-type="date" />
          <dx-column data-field="readDate" width="120px" data-type="date" />
          <dx-column data-field="read" width="60px" :allow-sorting="false" cell-template="readCellTemplate" />
          <template #readCellTemplate="{ data }">
            <p-icon
              :name="data.value ? 'bi-envelope-open': 'bi-envelope'"
              :color="data.value ? 'white': 'warning'"
            />
          </template>
          <dx-column data-field="message" width="200px" caption="Details" :allow-sorting="false" cell-template="linkCellTemplate" />
          <template #linkCellTemplate="{ data }">
            <a v-if="data.data.topic === 'PROBLEM'" class="text-primary" @click="onClickOpenProblem(data.value)">See more details</a>
          </template>
          <!--endregion ::DataGird columns -->
          <!--region    ::DataGird actions -->
          <dx-column data-field="id" caption="Actions" alignment="center" cell-template="itemActions" :width="100" :allow-resizing="false" :fixed="true" fixed-position="left" />
          <template #itemActions="{data}">
            <div>
              <!-- TODO: bu kısıma normal title a tıklayarak erişebilmeesine rağmen disabled yapıyoruz. tutarlılaştırmak lazım. -->
              <dx-util-button
                :disabled="data.data.read"
                type="success" icon="bi bi-envelope-fill"
                class="mr-half" hint="Merge Product MSKU/FNSKU"
                @click="onShowNotification(data)"
              />
              <dx-util-button
                type="danger" icon="bi bi-trash-fill"
                hint="Delete"
                @click="onClickDelete(data)"
              />
            </div>
          </template>
          <!--endregion ::DataGird actions -->
        </dx-data-grid>
      </div>
      <div class="col-md-12 px-0">
      <dx-util-popup
        ref="readNotificationPopupRef"
        :drag-enabled="false"
        :close-on-outside-click="false"
        :show-close-button="true"
        :show-title="true"
        :width="getPopupWidth"
        :height="getPopupHeight"
        :title="selectedNotification.title"
        content-template="popup-content"
        @hidden="onHideNotification"
      >
        <dx-util-position at="center" my="center" />
        <template #popup-content>
        <dx-util-scroll-view height="100%" width="100%">
          <div class="d-flex flex-column">
            <h5 class="text-center">
              Message
            </h5>
            <p v-html="selectedNotification.message" />
          </div>
        </dx-util-scroll-view>
        </template>
        <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="before" :options="acceptPopupButtonOptions" />
        <dx-util-toolbar-item widget="dxButton" toolbar="bottom" location="after" :options="closePopupButtonOptions" />
      </dx-util-popup>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable no-unused-vars */
/* eslint-disable vue/no-v-html */

import GridBase from '@core/dev-extreme/mixins/grid/base'
import { ValueToEnum } from '@robustshell/utils/index'
import notificationService from '@/http/requests/notification/notificationService'
import truncate from 'lodash/truncate'
import { confirm } from 'devextreme/ui/dialog'
import { notificationDataSource } from './notificationStore'

export default {
  mixins: [GridBase],
  data() {
    return {
      popupCreateEditVisible: false,
      dataSource: notificationDataSource,
      selectedNotification: {},
      acceptPopupButtonOptions: {
        text: 'Mark as read',
        type: 'success',
        onClick: () => {
          this.onUpdateNotification()
        },
      },
      closePopupButtonOptions: {
        text: 'Cancel',
        type: 'danger',
        onClick: () => {
          this.onClosePopup()
        },
      },
    }
  },
  computed: {
    dataGrid() {
      const grid = this.$refs.notificationGridRef.instance
      return grid
    },
  },
  mounted() {
    this.initialLoad()
  },
  methods: {
    getPopupWidth() {
      return window.innerWidth / 4
    },
    getPopupHeight() {
      return window.innerHeight / 3
    },
    getEnumFormated(items, val) {
      const enumItem = ValueToEnum(items, val)
      return enumItem.text
    },
    initialLoad() {
      const dataSource = this.dataGrid.getDataSource()
      dataSource.load()
    },
    onShowNotificationLink(data) {
      const popup = this.$refs.readNotificationPopupRef.instance
      this.selectedNotification = { ...data.data }
      this.selectedNotification.title = this.getEnumFormated(this.$enums.NotificationTypeEnum, this.selectedNotification.topic)
      this.selectedNotification.message = this.getMessageFormated(data.data.message)
      popup.show()
    },
    onShowNotification(e) {
      const popup = this.$refs.readNotificationPopupRef.instance
      this.selectedNotification = { ...e.row.data }
      this.selectedNotification.title = this.getEnumFormated(this.$enums.NotificationTypeEnum, this.selectedNotification.topic)
      this.selectedNotification.message = this.getMessageFormated(e.row.data.message)
      popup.show()
    },
    async onClickDelete(e) {
      const confirmResult = confirm(
        "Are you sure you want to delete this message? You won't be able to revert this!",
        'Confirmation',
      )
      confirmResult.then(async dialogResult => {
        if (dialogResult) {
          const result = await notificationService.deleteById(e.row.data.id)
          if (result) {
            this.rowRemoved()
          }
        }
      })
    },
    onUpdateNotification() {
      const ids = []
      ids.push(this.selectedNotification.id)
      const dataSource = this.dataGrid.getDataSource()
      notificationService.updateNotificationList(ids).then(result => {
        if (result.data) {
          dataSource.reload()
          this.onClosePopup()
        }
      })
    },
    onHideNotification() {
      Object.assign(this.selectedNotification, {})
    },
    onClosePopup() {
      const popup = this.$refs.readNotificationPopupRef.instance
      Object.assign(this.selectedNotification, {})
      popup.hide()
    },
    rowRemoved(e) {
      const dataSource = this.dataGrid.getDataSource()
      dataSource.reload()
    },
    getMessageFormated(message) {
      const item = JSON.parse(message)
      return this.$options.filters.cleanNotification(item.message)
    },
    getMessage(data) {
      const item = JSON.parse(data.data.message)
      return truncate(item.message.title, {
        length: 1000,
      })
    },
    onClickOpenProblem(message) {
      const item = JSON.parse(message)
      this.$router.push(item.link)
    },
  },
}
</script>

<style scoped>
@keyframes throb {
  0% { transform: scale(1); }
  50% { transform: scale(1.2); }
  100% { transform: scale(1); }
}
.notif-alert {
  display: inline-block;
  animation: throb 2s ease-in-out infinite;
}

</style>
