/* eslint-disable no-param-reassign */
import DataSource from 'devextreme/data/data_source'
import CustomStore from 'devextreme/data/custom_store'
import notificationService from '@/http/requests/notification/notificationService'
import Pager from '@core/dev-extreme/utils/pager'

const store = new CustomStore({
  key: 'id',
  cacheRawData: false,
  loadMode: 'processed',
  useDefaultSearch: true,
  load: loadOptions => {
    const options = {}
    const sort = (loadOptions.sort && loadOptions.sort.length > 0) ? loadOptions.sort : [{ selector: 'id', desc: true }]
    const pager = new Pager(options, sort)
    pager.setPageNumber(loadOptions.skip, loadOptions.take)
    pager.setSortOrder(sort)
    const pageableQuery = `${pager.toCreatePageable()}&sort=${pager.toCreateSortOrder()}`
    return notificationService
      .fetchAll(pageableQuery)
      .then(response => {
        const data = response.data
        return {
          data: data.content,
          totalCount: data.totalElements,
        }
      })
  },
  byKey: key => notificationService
    .fetchById(key).then(response => {
      const data = response.data
      return data
    }),
  remove: key => {
    notificationService.deleteById(key)
  },
})

const notificationDataSource = new DataSource({
  store: store,
  pageSize: 50,
  paginate: true,
})

export {
  notificationDataSource,
}
